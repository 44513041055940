<template>
  <div class="pay-dialog">
    <div>
      <el-form :ref="formName" :model="formData">
        <el-form-item label="发布时长" prop="time">
          <el-select v-model="formData" placeholder="请选择">
            <el-option
              v-for="item in dateArray"
              :key="item.value"
              :label="item.label"
              :value="item">
            </el-option>
          </el-select>
          <template v-if="deadline">
            截至时间：{{ deadline }}
          </template>
        </el-form-item>
        <el-form-item>
          总的服务费用：{{ formData.price }}元
        </el-form-item>
        <el-form-item>
          <pay
            :pay-amount="formData.price"
            @pay="onPay"
          ></pay>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {goods} from "@/apis/index";
import {addDays} from 'date-fns'

export default {
  name: 'payDialog',
  components: {
    pay: () => import('@CMP/common/BuyPay')
  },
  data() {
    return {
      dateArray: [],
      formData: {
        value: 0,
        price: 0,
      },
      formName: 'formName',
    }
  },
  watch: {},
  computed: {
    deadline() {
      const {value} = this.formData;
      if (value) {
        return this.dateToString(addDays(new Date(), value), 'yyyy-MM-dd HH:mm:ss')
      }
      return ''
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  methods: {
    onPay({payWay}) {
      this.$emit('pay', {
        payWay: payWay,
        price: this.formData.price,
        date: this.formData.value
      });
      this.$refs[this.formName].resetFields();
    },
    getFees() {
      goods.getFees({
        type: this.type
      }).then(res => {
        let {retdata = []} = res;
        let dateArray = retdata.map(item => {
          return {
            label: item.desc,
            value: item.day,
            price: item.price,
          }
        })
        this.dateArray = dateArray;
        this.formData = dateArray[0];
      })
    }
  },
  beforeMount() {
    this.getFees();
  }
}
</script>

<style scoped lang="scss">
.pay-dialog {
  & ::v-deep .el-form-item, .el-form-item {
    margin-bottom: 10px !important;
  }
}
</style>
